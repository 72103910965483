import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { openDB, deleteDB } from 'idb';
import { RestService } from './rest.service';
import { AppConfig } from '../app.config';

// NOTE: browser Edge does not support getKeyFromIndex, getAllFromIndex, or getAllKeysFromIndex.

@Injectable({
  providedIn: 'root',
})
export class StoreIdbService {
  public data = [];
  db!: any;

  public versionIdb = 1;

  constructor(public router: Router, private rest: RestService, public configService: AppConfig) {
    this.initDb();
  }

  public async initDb() {
    try {
      this.db = await openDB('contaplex', this.versionIdb, {
        upgrade(db, oldVersion) {
          db.createObjectStore('provinces', { keyPath: 'id', autoIncrement: true });
          db.createObjectStore('communes', { keyPath: 'id', autoIncrement: true });
          db.createObjectStore('economic-activities', { keyPath: 'id', autoIncrement: true });
        },
        blocked() {
          console.log(
            '===========  Bloqueado initDb por tener conecciones abiertas  ==============='
          );
        },
      });
    } catch (e) {
      console.log(e);
    }

    return this.db;
  }

  getDataModel(model: string) {
    return this.rest.index(model, {});
  }

  public getObjectStoreIdb(nameObjectStore: string) {
    return new Promise((resolve, reject) => {
      openDB('contaplex', this.versionIdb).then(() => {
        return this.db.getAll(nameObjectStore).then(
          (data: any) => {
            resolve(data);
          },
          (error: any) => {
            reject(error);
          }
        );
      });
    });
  }

  public async restoreDB() {
    const db = await openDB('contaplex', this.versionIdb);
    db.close();
    await deleteDB('contaplex', {
      // blocked() {
      //   console.log("===========  Bloqueado restoreDB por tener conecciones abiertas  ===============");
      // }
    });
  }

  public addRegisterIdb(data: any, nameObjectStore: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const tx = this.db.transaction(nameObjectStore, 'readwrite');
        data.forEach((item: any) => {
          if (item) {
            tx.store.add(item);
          }
        });
        await tx.done;
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  public addOneDataInIdb(data: any, collection: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB('contaplex', this.versionIdb);

        const tx = db.transaction(collection, 'readwrite');
        tx.store.add(data);
        await tx.done;

        db.close();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  public deleteOneDataInIdb(id: any, collection: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB('contaplex', this.versionIdb);

        const tx = db.transaction(collection, 'readwrite');
        tx.store.delete(id);
        await tx.done;

        db.close();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  public truncateObjectStore(collection: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const db = await openDB('contaplex', this.versionIdb);

        db.clear(collection);

        db.close();
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
  }

  public addRegisterIdbPermissions(data: any) {
    if (data.length > 0) {
      data.forEach((v: any, i: any) => {
        let newItem = { id: i, permission: v };
        this.db.add('permissions', newItem).then(
          () => {
            console.log('new record added');
          },
          (error: any) => {
            console.log(error);
          }
        );
      });
    }
  }

  async findAllIdb(object: string) {
    let dataIdbSector: any = await this.getObjectStoreIdb(object);
    return dataIdbSector;
  }

  async findFilterIdb(object: string, key: string, value: string) {
    let dataIdbSector: any = await this.getObjectStoreIdb(object);
    return dataIdbSector.filter((item: any) => item[key] === value);
  }
}
